import React from 'react';
import Icon1 from '../../images/icon_phone.jpeg';
import Icon2 from '../../images/icon_address.jpeg';
import Icon3 from '../../images/icon_message.jpeg';
import { Button } from '../ButtonElements';
import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    Column2,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    Subtitle1,
    BtnWrap,
    ImgWrap,
    Img,
    ServicesIcon,
    NavBtnLink
}
from '../InfoSection/InfoElements';

const InfoSection = (
    {
        lightBg, 
        id, 
        imgStart, 
        topLine, 
        lightText, 
        headline, 
        darkText, 
        description, 
        buttonLabel, 
        img,
        alt,
        primary,
        dark,
        description2,
        description3,
        description4,
        description5,
        dark2
    }) => {
    return (
        <>
           <InfoContainer lightBg={lightBg} id={id}>
               <InfoWrapper>
                   <InfoRow imgStart={imgStart}>
                       <Column1>
                       <TextWrapper>
                           <TopLine>{topLine}</TopLine>
                           <Heading lightText={lightText}>{headline}</Heading>
                           <Subtitle darkText={darkText}>{description5}</Subtitle>
                           <Subtitle darkText={darkText}><ServicesIcon src={Icon1}/>{description}</Subtitle>
                           <Subtitle darkText={darkText}><ServicesIcon src={Icon3}/> {description2}</Subtitle>
                           <Subtitle darkText={darkText}><ServicesIcon src={Icon2}/> {description3}</Subtitle>
                           <Subtitle1 darkText={darkText}> {description4}</Subtitle1>
                           <BtnWrap>
                
                             <NavBtnLink
                              to ='contactus'
                              smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                                offset={-80}
                                primary={primary ? 1 : 0}
                                dark={dark ? 1: 0}
                                dark2={dark2 ? 1: 0}
                                
                              >
                                 Email us
                             </NavBtnLink>
                             
                           </BtnWrap>
                       </TextWrapper>
                       </Column1>
                       <Column2>
                        <ImgWrap>
                            <Img src={img} alt={alt} />
                        </ImgWrap>
                       </Column2>
                   </InfoRow>
               </InfoWrapper>
               </InfoContainer> 
        </>
    )
}

export default InfoSection
