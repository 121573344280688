import styled from 'styled-components';
import {Link} from 'react-router-dom'

export const FooterContainer = styled.div`
    background-color: #f9f9f9;
    height:400px;
    
    @media screen and (max-width:768px){
        height:600px;
    }
    
`

export const FooterWrap = styled.div`
    padding: 0px 100px;
    display: flex;
    flex-direction: column;
    justify-content: none;
    max-width: 1100px;
    margin: 0 auto;
    

    @media screen and (max-width:768px){
        margin-top:-90px;
    }
    
`
export const FooterLinksContainer = styled.div`
    display: flex;
    justify-content: center;

    @media screen and (max-width: 768px){
        display:inline;
        padding-top: -32px;
    }
`

export const FooterLinksWrapper = styled.div`
    display: flex;

    @media screen and (max-width: 820px){
        flex-direction: column;
    }
`

export const FooterLinkItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px;
    margin-left:100px;
    margin-right:100px;

    text-align:center;
    width: 160px;
    box-sizing: border-box;
    color: #010606;

    @media screen and (max-width: 420px){
        margin:0;
        padding:10px;
        width:100%;
    }
`

export const FooterLinkTitle = styled.h1`
    font-size: 24px;
    margin-bottom: 0px;
    justify-content: center;
    align-items: center;

    @media screen and (max-width:768px){
        font-size:24px;
    }
`

export const FooterLink = styled.div`
    color: #010606;
    text-decoration: none;
    margin-bottom: 0px;
    font-size: 10px;

    &:hover{
        color: #2596be;
        transition: 0.3s ease-out
    }
`

export const SocialMedia = styled.section`
    max-width: 1000px;
    width: 100%;
`

export const SocialMedialWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    margin: 40px auto 0 auto;

    @media screen and (max-width: 820px){
        flex-direction: column;
    }
`

export const SocialLogo = styled(Link)`
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-weight: bold;
    
`

export const WebsiteRights = styled.small`
    color: #fff;
    margin-bottom: 16px;
`

export const SocilalIcons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
`

export const SocialIconLink = styled.a`
    color: #fff;
    font-size: 24px;
`

export const PromoP = styled.p`
    margin-top:10px;
    color:#010606;
    font-size: 14px;
    text-align: center;
    max-width: 600px;

@media screen and (max-width:768px){
    font-size:12px;
}
`
export const ServicesIcon = styled.img`
    height: 160px;
    width:160px;
    margin-bottom: 10px;

    @media screen and (max-width:768px){
        height : 50px;
        width:50px;
        margin-top:1px;
    }
`