import React, {useState} from 'react'
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import { homeObjOne } from '../components/InfoSection/Data';
import Services from '../components/Services';
import Footer from '../components/Footer';
import Promo from '../components/Promo';
import WhyusSection from '../components/WhyusSection';
import {homeObjTwo} from '../components/WhyusSection/Data';
import ContactusSection from '../components/ContactusSection';
import {homeObjThree} from '../components/ContactusSection/data';

const Home = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
           <Sidebar isOpen={isOpen} toggle={toggle} />
           <Navbar toggle={toggle} />
           <HeroSection />
           <InfoSection {...homeObjOne}/>
           <WhyusSection {...homeObjTwo}/>
           <Promo />
           <Services />
           <ContactusSection {...homeObjThree}/>
           <Footer />

        </>
    )
}

export default Home
