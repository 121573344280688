import React from 'react'
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2'
import { 
    Container, 
    FormButton, 
    FormContent, 
    FormLabel, 
    FormWrap,
    Icon,
    Form,
    FormH1,
    FormInput,
    FormSelect

} from './GetaquoteElements';


const opensweetalert = () => {
    Swal.fire({
        title: 'Request sent',
        text: "Thank you, we will get back to you as soon as possible",
        type: 'success',
      }).then(function() {
        window.location = "/";
    });
}

const Getaquote = () => {
    const [value, setValue] = React.useState('');
    const handleChange = (event) => {
        setValue(event.target.value);
      };

    function sendEmail(e) {
        e.preventDefault();
    
        emailjs.sendForm('service_g3552u3', 'termiite_quote', e.target, 'user_jtGV0Mnz1wsjHICOcg2zH')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset()
        }

       

    
    return (
        <>
        <Container>
            <FormWrap>
                <Icon to="/">Termiite</Icon>
                <FormContent>
                    <Form onSubmit={sendEmail}>
                        <FormH1>Get a quote here</FormH1>
                        <FormLabel htmlFor='for'>Your name/ Company name</FormLabel>
                        <FormInput type='text' name="name" />
                        <FormLabel htmlFor='for'>Email</FormLabel>
                        <FormInput type='text' name="email" onChange={handleChange}/>
                        <FormLabel htmlFor='for'>Cell phone</FormLabel>
                        <FormInput type='text' name="cell" />
                        <FormLabel htmlFor='for'>What type of project do you want to build?</FormLabel>
                        <FormSelect name="types">
                        <option value="Church Website">Church Website</option> 
                        <option value="A Marketing Website">A Marketing Website</option>
                        <option value="An e-Commerce Website">An e-Commerce Website</option>
                        <option value="A Mobile Application">A Mobile Application</option>
                        <option value="A Custom Software Project">A Custom Software Project</option>
                        </FormSelect>
                        <FormLabel htmlFor='for'>Description</FormLabel>
                        <textarea rows="10" cols="30" name="description">

                        </textarea>
                        <br/>
                        {!value?
                        <FormButton  type='submit' style={{background:"grey"}}  >Fill the form</FormButton>:
                        <FormButton  type='submit' onClick={opensweetalert} >Send</FormButton>}
                        
                    </Form>
                </FormContent>
            </FormWrap>
        </Container> 
        </>
    )
}

export default Getaquote;
