export const homeObjOne = {
    id:'about',
    lightBg:false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'About us',
    description: 'We are an emerging company, providing IT services and solutions. We have a very talented group of passionate developers who work hard to provide incredible products, excellent services, and amazing customer support. Our mission is to create better products that best meet your business model but that is just the tip of the iceberg. We help you engage and communicate with your clients effectively to be more productive with your business. We firmly believe in technology but also the business experience of our customers.',
    buttonLabel: 'Why us',
    imgStart: false,
    img: require('../../images/about-us.svg').default,
    alt:'alt',
    dark: true,
    primary: true,
    darkText: false
};

