import React from 'react';
import Icon1 from '../../images/icon_service.jpeg';
import Icon2 from '../../images/icon_wordpress.jpeg';
import Icon3 from '../../images/icon_maintenance.jpeg';
import {
    FooterContainer, 
    FooterWrap, 
    FooterLinksContainer, 
    FooterLinksWrapper, 
    FooterLinkItems, 
    FooterLinkTitle, 
    FooterLink,
    PromoP,
    ServicesIcon, 
    SocialMedia, 
    SocialMedialWrap, 
    SocialLogo, 
    WebsiteRights, 
    SocialIconLink, 
    SocilalIcons } from './promoElements';

const Promo = () => {
    return (
        <FooterContainer id="promo">
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                        <ServicesIcon src={Icon1}/>
                        <FooterLinkTitle>10% OFF </FooterLinkTitle>
                        <PromoP>
                        Is your business non profit? We give this discount to all non profit organisations.
                        </PromoP>
                            
                        </FooterLinkItems>
                        <FooterLinkItems>
                        <ServicesIcon src={Icon2}/>
                        <FooterLinkTitle>20% OFF </FooterLinkTitle>
                        <PromoP>
                            We take advantage of fast development practices. Get 20% off for wordpress packages.
                        </PromoP>
                            
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                        <ServicesIcon src={Icon3}/>
                        <FooterLinkTitle>100% Free</FooterLinkTitle>
                        <PromoP>
                        Work with us and get your final product maintained free of charge.
                        </PromoP>
                            
                        </FooterLinkItems>
                       
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                
            </FooterWrap>
        </FooterContainer>
    )
}

export default Promo
