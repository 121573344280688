import React from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';


import Home from './pages';
import QuotePage from './pages/quote';
import ContactusPage from './pages/contact';


function App() {
  return (
    <> 
    <Router >
      {/* Update */}
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/getaquote" component={QuotePage} exact />
        <Route path="/contactus" component={ContactusPage} exact />
      </Switch>
    </Router>
    {/* WhatsApp icon */}
    <a
        href="https://wa.me/27745139343"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>

    </>
    
  );
  
}

export default App;
