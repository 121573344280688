export const homeObjTwo = {
    id:'whyus',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    headline: 'Why Us?',
    description: 'We have worked on amazing projects with our clients and we are proud of every single one of them.',
    buttonLabel: 'Promos',
    imgStart: true,
    img: require('../../images/whyus.svg').default,
    alt:'alt',
    dark: false,
    primary: false,
    darkText: true
};
