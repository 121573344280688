import React from 'react';
import Icon1 from '../../images/website.svg'
import Icon2 from '../../images/app.svg'
import Icon3 from '../../images/design.svg'
import {
    ServicesContainer, 
    ServicesH1, 
    ServicesWrapper, 
    ServicesCard, 
    ServicesIcon, 
    ServicesH2, 
    ServicesP} from './ServicesElements'

const Services = () => {
    return (
        <ServicesContainer id="services">
            <ServicesH1>Our Services</ServicesH1>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesIcon src={Icon1}/>
                    <ServicesH2>Web Development</ServicesH2>
                    <ServicesP>Captivate your customers and keep them immersed in your website with amazing graphics by making it easy for them to find what they want. The look and feel of a website can inspire, but to achieve success, quality, and user-friendliness is crucial. We strongly believe that the greatest user experience comes from combining these two dimensions of design.
</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon2}/>
                    <ServicesH2>App Development</ServicesH2>
                    <ServicesP>A successful mobile app effectively sets your services at your customers' fingertips, on the device they use more than any other. We have a dedicated team of mobile app developers who design and create applications to meet your exact requirements. Bring your mobile app to life to offer an outstanding experience with engaging design and enhanced functionality.
</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon3}/>
                    <ServicesH2>Graphic Design</ServicesH2>
                    <ServicesP>One of the first things people see when they come across your business is your logo and it is important that it represents your company in the right way to leave a lasting impression and an engaging audience. We keep ourselves at the forefront of emerging design trends, which means we can offer you the latest visual solutions to improve the quality of your brand identity.
</ServicesP>
                </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer>
    )
}

export default Services
