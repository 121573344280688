import React from 'react'
import ContactUs from '../components/ContactPage'
import ScrollToTop from '../components/ScrollToTop'

const ContactusPage = () => {
    return (
        <>
            <ScrollToTop />
            < ContactUs/>
        </>
    )
}

export default ContactusPage
