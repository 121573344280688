export const homeObjThree = {
    id:'contact',
    lightBg:true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Contact',
    headline: '',
    description5: 'Name: Chadrack Mulamba',
    description: 'Phone: +27 74 513 9343',
    description2: 'Email: chadrackmulamba2@gmail.com',
    description3: 'Address: 32 Mayo Road,',
    description4: 'BlackHeath, Randburg, JHB',
    buttonLabel: 'Email us',
    imgStart: false,
    img: require('../../images/map-3.svg').default,
    alt:'alt',
    dark: false,
    primary: false,
    darkText: true
};