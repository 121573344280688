import React, {useState} from 'react';

import Video from '../../videos/bgvideo.mp4';
import { Button } from '../ButtonElements';
import Background from '../../images/bg.png';
import
 {
    HeroContainer, 
    HeroBg, 
    VideoBg, 
    HeroContent, 
    HeroH1, 
    HeroP, 
    HeroBtnWrapper,
    ArrowForward,
    ArrowRight,
    NavBtnLink
} 
from './HeroElements';



const HeroSection = () => {
  const [hover, setHover] = useState (false);

  const onHover = () => {
       setHover(!hover);
   };

    return (
        <HeroContainer>
            <HeroBg>
                <VideoBg autoPlay playsInline loop muted src={Video} type="video/mp4" style={{background:`url(${Background})`}} />
            </HeroBg>
            <HeroContent>
                <HeroH1>Ideas to Action</HeroH1>
                <HeroP>We simply want to make what you want</HeroP>
                <HeroBtnWrapper>
                    <NavBtnLink 
                    to="getaquote"
                    onMouseEnter={onHover}
                    onMouseLeave={onHover}
                    primary="true"
                    dark = "true"
                    >
                    Get a quote {hover ? <ArrowForward /> : <ArrowRight />}
                    </NavBtnLink>
            </HeroBtnWrapper>
            
            
            </HeroContent>
           
        </HeroContainer>
    )
}
export default HeroSection
