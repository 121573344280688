import React from 'react';
import {animateScroll as scroll} from 'react-scroll';
import {
    FaFacebook, 
    FaInstagram, 
    FaYoutube, 
    FaTwitter,
    FaLinkedin
    } from 'react-icons/fa'
import {
    FooterContainer, 
    FooterWrap, 
    FooterLinksContainer, 
    FooterLinksWrapper, 
    FooterLinkItems, 
    FooterLinkTitle, 
    FooterLink, 
    SocialMedia, 
    SocialMedialWrap, 
    SocialLogo, 
    WebsiteRights, 
    SocialIconLink, 
    SocilalIcons } from './FooterElements'

const Footer = () => {

    const toggleHome = () =>{
        scroll.scrollToTop()
    };

    return (
        <FooterContainer>
            <FooterWrap>
                <SocialMedia>
                    <SocialMedialWrap>
                        <SocialLogo to= "/" onClick={toggleHome} >
                            Termiite
                        </SocialLogo>
                        <WebsiteRights>Termiite © {new Date().getFullYear()} All rights reserved.</WebsiteRights>
                        <SocilalIcons>
                            <SocialIconLink href="https://web.facebook.com/Termiite-109332104625854" target="_blank" arial-label="Facebook">
                                <FaFacebook />
                            </SocialIconLink>
                            <SocialIconLink href="https://www.instagram.com/termiite1/?hl=en" target="_blank" arial-label="Instagram">
                                <FaInstagram />
                            </SocialIconLink>
                            <SocialIconLink href="https://www.youtube.com/channel/UCgBh_O4ISK2NUbZGwM1XapQ" target="_blank" arial-label="Youtube">
                                <FaYoutube />
                            </SocialIconLink>
                            <SocialIconLink href="https://twitter.com/Termiite1" target="_blank" arial-label="Twitter">
                                <FaTwitter />
                            </SocialIconLink>
                            <SocialIconLink href="https://www.linkedin.com/feed/" target="_blank" arial-label="Linkedin">
                                <FaLinkedin />
                            </SocialIconLink>
                            
                        </SocilalIcons>
                    </SocialMedialWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer
